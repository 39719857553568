*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-left-16 {
  left: -4rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-1\.5 {
  top: -.375rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.left-0 {
  left: 0;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-12 {
  top: 3rem;
}

.top-4 {
  top: 1rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-11 {
  height: 2.75rem;
}

.h-16 {
  height: 4rem;
}

.h-24 {
  height: 6rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.min-h-fit {
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-24 {
  width: 6rem;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-6 {
  width: 1.5rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade {
  animation: 1s both fade;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.animate-fadeIn {
  animation: 1s both fadeIn;
}

@keyframes lg-bn1 {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

.animate-logo-bana1 {
  animation: 3s linear infinite lg-bn1;
}

@keyframes lg-bn2 {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

.animate-logo-bana2 {
  animation: 3s linear infinite lg-bn2;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate-zoomIn {
  animation: 1s both zoomIn;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: .5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[4\.3rem\] {
  gap: 4.3rem;
}

.gap-x-12 {
  -moz-column-gap: 3rem;
  column-gap: 3rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.divide-dark > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(66 70 86 / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.\!overflow-visible {
  overflow: visible !important;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[2\.8rem\] {
  border-radius: 2.8rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-t-\[2\.5rem\] {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-dark {
  --tw-border-opacity: 1;
  border-color: rgb(66 70 86 / var(--tw-border-opacity));
}

.border-dark\/45 {
  border-color: #42465673;
}

.border-light {
  --tw-border-opacity: 1;
  border-color: rgb(247 249 255 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(8 102 200 / var(--tw-border-opacity));
}

.border-t-transparent {
  border-top-color: #0000;
}

.border-opacity-70 {
  --tw-border-opacity: .7;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-dark\/75 {
  background-color: #424656bf;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-light {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 255 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(8 102 200 / var(--tw-bg-opacity));
}

.bg-seclight {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 235 / var(--tw-bg-opacity));
}

.bg-seclight\/45 {
  background-color: #fff7eb73;
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(233 143 27 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-85 {
  --tw-bg-opacity: .85;
}

.bg-\[url\(\'\.\.\/public\/SMbg_heada\.png\'\)\] {
  background-image: url("SMbg_heada.c033e32e.png");
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#101212\] {
  --tw-gradient-from: #101212 var(--tw-gradient-from-position);
  --tw-gradient-to: #10121200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-300 {
  --tw-gradient-from: #86efac var(--tw-gradient-from-position);
  --tw-gradient-to: #86efac00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#08201D\] {
  --tw-gradient-to: #08201d var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-origin-content {
  background-origin: content-box;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[1rem\] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-primary {
  font-family: Montserrat;
}

.font-secondary {
  font-family: Permanent Marker;
}

.\!text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(66 70 86 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(247 249 255 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(8 102 200 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(233 143 27 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-dark\/10 {
  --tw-shadow-color: #4246561a;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-dark {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(66 70 86 / var(--tw-ring-opacity));
}

.ring-opacity-15 {
  --tw-ring-opacity: .15;
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.animate-faster {
  animation-duration: .5s;
}

.animate-fast {
  animation-duration: .8s;
}

.animate-slow {
  animation-duration: 2s;
}

.animate-ease-in-cubic {
  animation-timing-function: cubic-bezier(.32, 0, .67, 0);
}

.animate-ease-in-out-quad {
  animation-timing-function: cubic-bezier(.45, 0, .55, 1);
}

.animate-ease-in-quint {
  animation-timing-function: cubic-bezier(.64, 0, .78, 0);
}

.animate-delay-100 {
  animation-delay: .1s;
}

.animate-delay-150 {
  animation-delay: .15s;
}

.animate-delay-200 {
  animation-delay: .2s;
}

.animate-delay-300 {
  animation-delay: .3s;
}

.animate-delay-500 {
  animation-delay: .5s;
}

.animate-delay-700 {
  animation-delay: .7s;
}

.animate-duration-500 {
  animation-duration: .5s;
}

.animate-thrice {
  animation-iteration-count: 3;
}

.\[font-variant\:small-caps\] {
  font-variant: small-caps;
}

.mask-marquee {
  -webkit-mask-image: linear-gradient(90deg, #0000 0, #424656 12.5% 87.5%, #0000);
  mask-image: linear-gradient(90deg, #0000 0, #424656 12.5% 87.5%, #0000);
}

.rfm-marquee-container {
  width: var(--width);
  transform: var(--transform);
  flex-direction: row;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.rfm-marquee-container:hover div {
  animation-play-state: var(--pause-on-hover);
}

.rfm-marquee-container:active div {
  animation-play-state: var(--pause-on-click);
}

.rfm-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.rfm-overlay:before, .rfm-overlay:after {
  pointer-events: none;
  z-index: 20;
  touch-action: none;
  background: linear-gradient(to right, var(--gradient-color), #42465600);
  content: "";
  width: var(--gradient-width);
  height: 100%;
  position: absolute;
}

.rfm-overlay:after {
  top: 0;
  right: 0;
  transform: rotateZ(180deg);
}

.rfm-overlay:before {
  top: 0;
  left: 0;
}

.rfm-marquee {
  z-index: 10;
  display: flex;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.rfm-marquee {
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  min-width: var(--min-width);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  flex-direction: row;
  flex: none;
  align-items: center;
}

.rfm-initial-child-container {
  flex-direction: row;
  flex: none;
  align-items: center;
  min-width: auto;
  display: flex;
}

.rfm-child {
  transform: var(--transform);
}

.before\:pointer-events-none:before {
  content: var(--tw-content);
  pointer-events: none;
}

.before\:mr-1:before {
  content: var(--tw-content);
  margin-right: .25rem;
}

.before\:mt-\[6\.5px\]:before {
  content: var(--tw-content);
  margin-top: 6.5px;
}

.before\:box-border:before {
  content: var(--tw-content);
  box-sizing: border-box;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-1:before {
  content: var(--tw-content);
  height: .25rem;
}

.before\:h-1\.5:before {
  content: var(--tw-content);
  height: .375rem;
}

.before\:w-2:before {
  content: var(--tw-content);
  width: .5rem;
}

.before\:w-2\.5:before {
  content: var(--tw-content);
  width: .625rem;
}

.before\:rounded-tl-md:before {
  content: var(--tw-content);
  border-top-left-radius: .375rem;
}

.before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:transition-all:before {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:content-none:before {
  --tw-content: none;
  content: var(--tw-content);
}

.after\:pointer-events-none:after {
  content: var(--tw-content);
  pointer-events: none;
}

.after\:ml-1:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.after\:mt-\[6\.5px\]:after {
  content: var(--tw-content);
  margin-top: 6.5px;
}

.after\:box-border:after {
  content: var(--tw-content);
  box-sizing: border-box;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-1:after {
  content: var(--tw-content);
  height: .25rem;
}

.after\:h-1\.5:after {
  content: var(--tw-content);
  height: .375rem;
}

.after\:w-2:after {
  content: var(--tw-content);
  width: .5rem;
}

.after\:w-2\.5:after {
  content: var(--tw-content);
  width: .625rem;
}

.after\:flex-grow:after {
  content: var(--tw-content);
  flex-grow: 1;
}

.after\:rounded-tr-md:after {
  content: var(--tw-content);
  border-top-right-radius: .375rem;
}

.after\:border-r:after {
  content: var(--tw-content);
  border-right-width: 1px;
}

.after\:border-t:after {
  content: var(--tw-content);
  border-top-width: 1px;
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-none:after {
  --tw-content: none;
  content: var(--tw-content);
}

.placeholder-shown\:border:placeholder-shown {
  border-width: 1px;
}

.focus-within\:border:focus-within {
  border-width: 1px;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
}

.hover\:animate-swing:hover {
  animation: 1s both swing;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.hover\:bg-white\/40:hover {
  background-color: #fff6;
}

.hover\:text-opacity-80:hover {
  --tw-text-opacity: .8;
}

.hover\:opacity-40:hover {
  opacity: .4;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-dark\/20:hover {
  --tw-shadow-color: #42465633;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:animate-swing:hover {
  transform-origin: top;
}

.focus\:border-2:focus {
  border-width: 2px;
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(8 102 200 / var(--tw-border-opacity));
}

.focus\:\!border-t-primary:focus {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 102 200 / var(--tw-border-opacity)) !important;
}

.focus\:border-t-transparent:focus {
  border-top-color: #0000;
}

.focus\:bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.focus\:bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.focus\:bg-white\/40:focus {
  background-color: #fff6;
}

.focus\:opacity-\[0\.85\]:focus {
  opacity: .85;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:opacity-\[0\.85\]:active {
  opacity: .85;
}

.active\:shadow-none:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:border-0:disabled {
  border-width: 0;
}

.disabled\:bg-gray-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.disabled\:bg-opacity-50:disabled {
  --tw-bg-opacity: .5;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:shadow-none:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-\[4\.1\] {
  line-height: 4.1;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:before\:border-transparent:before {
  content: var(--tw-content);
  border-color: #0000;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:after\:border-transparent:after {
  content: var(--tw-content);
  border-color: #0000;
}

.peer:focus ~ .peer-focus\:text-\[11px\] {
  font-size: 11px;
}

.peer:focus ~ .peer-focus\:leading-tight {
  line-height: 1.25;
}

.peer:focus ~ .peer-focus\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:before\:border-l-2:before {
  content: var(--tw-content);
  border-left-width: 2px;
}

.peer:focus ~ .peer-focus\:before\:border-t-2:before {
  content: var(--tw-content);
  border-top-width: 2px;
}

.peer:focus ~ .peer-focus\:before\:\!border-gray-900:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:border-r-2:after {
  content: var(--tw-content);
  border-right-width: 2px;
}

.peer:focus ~ .peer-focus\:after\:border-t-2:after {
  content: var(--tw-content);
  border-top-width: 2px;
}

.peer:focus ~ .peer-focus\:after\:\!border-gray-900:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.peer:disabled ~ .peer-disabled\:text-transparent {
  color: #0000;
}

.peer:disabled ~ .peer-disabled\:before\:border-transparent:before, .peer:disabled ~ .peer-disabled\:after\:border-transparent:after {
  content: var(--tw-content);
  border-color: #0000;
}

@keyframes bounceInLeft {
  from {
    transform: translate3d(calc(-1 * var(--animate-distance, 2000px)), 0, 0) scale3d(3, 1, 1);
    opacity: 0;
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scale3d(1, 1, 1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scale3d(1, .98, 1);
  }

  90% {
    transform: translate3d(5px, 0, 0)scale3d(1, .995, 1);
  }
}

.intersect\:animate-bounceInLeft:not([no-intersect]) {
  animation: 1s cubic-bezier(.215, .61, .355, 1) both bounceInLeft;
}

.intersect\:animate-fade:not([no-intersect]) {
  animation: 1s both fade;
}

.intersect\:animate-fadeIn:not([no-intersect]) {
  animation: 1s both fadeIn;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.intersect\:animate-flipInX:not([no-intersect]) {
  animation: 1s both flipInX;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skew(-30deg);
  }

  60% {
    opacity: 1;
    transform: skew(20deg);
  }

  80% {
    transform: skew(-5deg);
  }
}

.intersect\:animate-lightSpeedInRight:not([no-intersect]) {
  animation: 1s ease-out both lightSpeedInRight;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }
}

.intersect\:animate-slideInLeft:not([no-intersect]) {
  animation: 1s both slideInLeft;
}

.intersect\:animate-flipInX:not([no-intersect]) {
  backface-visibility: visible;
}

@media not all and (width >= 1024px) {
  .max-lg\:w-11\/12 {
    width: 91.6667%;
  }
}

@media not all and (width >= 640px) {
  .max-sm\:absolute {
    position: absolute;
  }

  .max-sm\:mt-2 {
    margin-top: .5rem;
  }

  .max-sm\:min-w-full {
    min-width: 100%;
  }

  .max-sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .max-sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .max-sm\:bg-seclight {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 235 / var(--tw-bg-opacity));
  }

  .max-sm\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .max-sm\:pb-20 {
    padding-bottom: 5rem;
  }

  .max-sm\:text-center {
    text-align: center;
  }

  .max-sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .max-sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .group:hover .max-sm\:group-hover\:block {
    display: block;
  }
}

@media (width >= 640px) {
  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:sticky {
    position: sticky;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-48 {
    margin-top: 12rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:pl-6 {
    padding-left: 1.5rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-\[90\.89vh\] {
    height: 90.89vh;
  }

  .lg\:h-dvh {
    height: 100dvh;
  }

  .lg\:min-h-\[1000px\] {
    min-height: 1000px;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:bg-\[url\(\'\.\.\/public\/LGbg_heada\.png\'\)\] {
    background-image: url("LGbg_heada.c8037aa0.png");
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:\!text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .lg\:\[text-orientation\:upright\] {
    text-orientation: upright;
  }

  .lg\:\[writing-mode\:tb\] {
    writing-mode: tb;
  }
}

@media (width >= 1536px) {
  .\32 xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
}

/*# sourceMappingURL=index.c894ee2f.css.map */
